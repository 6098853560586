import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CareerModalLayout from './modal/CareerModalLayout';

const CareerLangTabComponent = ({ rootUrl }: { rootUrl: string }) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <div className={`tab-pane ${rootUrl === 'lang' || rootUrl === 'home' ? 'active' : ''}`} id="tng-Service" role="tabpanel">
      <h3>{t('career.langTab1')}</h3>
      <div className="car-process">
        <ul>
          <li>{t('career.langTab2')}</li>
          <li>{t('career.langTab3')}</li>
          <li>
            {t('career.langTab4')}
            <br />
            {t('career.langTab5')}
          </li>
          <li>{t('career.langTab6')}</li>
          <li>
            {t('career.langTab7')}
            <br />
            {t('career.langTab8')}
          </li>
          <li>{t('career.langTab9')}</li>
        </ul>
      </div>
      <FrameRow addClassName="car-formWrap">
        <FrameCol width={4}>
          <div className="title">
            <span></span>
            {t('career.langTab10')}
          </div>
          <ul className="item">
            <li>
              <i className="fa fa-mortar-board"></i>
              {t('career.langTab11')}
            </li>
            <li>
              <i className="fa fa-user-circle-o"></i>
              {t('career.langTab12')}
            </li>
            <li>
              <i className="fa fa-desktop"></i>
              {t('career.langTab13')}
            </li>
            <li>
              <i className="fa fa-globe"></i>
              {t('career.langTab14')}
            </li>
            <li>
              <i className="fa fa-language"></i>
              {t('career.langTab15')}
              <br />
              <small>{t('career.langTab16')}</small>
            </li>
            <li>
              <i className="fa fa-line-chart"></i>
              {t('career.langTab17')}
              <br />
              <small>{t('career.langTab18')}</small>
            </li>
            <li>
              <i className="fa fa-magic"></i>
              {t('career.langTab19')}
            </li>
          </ul>
        </FrameCol>
        <FrameCol width={4}>
          <div className="title">
            <span></span>
            {t('career.langTab20')}
          </div>
          <ul className="item">
            <li>
              <i className="fa fa-file-text-o"></i>
              {t('career.langTab21')}
              <br />
              <small>{t('career.langTab22')}</small>
            </li>
            <li>
              <i className="fa fa-list-alt"></i>
              {t('career.langTab23')}
            </li>
            <li>
              <i className="fa fa-vcard-o"></i>
              {t('career.langTab24')}
            </li>
            <li>
              <i className="fa fa-trophy"></i>
              {t('career.langTab25')}
            </li>
          </ul>
        </FrameCol>
        <FrameCol width={4}>
          <div className="title">
            <span></span>
            {t('career.langTab26')}
          </div>
          <ul className="item2">
            <li>
              {t('career.langTab27')}
              <a href="./assets/download/e4net_resume_lang.doc" download>
                <button type="button" className="btn btn-outline-primary btn-rounded pull-right mgt-10">
                  {t('career.langTab28')}
                  <i className="fa fa-download5 ml-2"></i>
                </button>
              </a>
            </li>
          </ul>
        </FrameCol>
      </FrameRow>
      <FrameCol addClassName="mt-40" textAlign="text-center">
        <button type="button" className="btn btn-md btn-primary btn-rounded fs-16 px-5" data-toggle="modal" data-target={`#tng-Service-Modal`} onClick={() => setIsModalOpen(true)}>
          {t('career.langTab29')}
        </button>
      </FrameCol>
      <CareerModalLayout isOpen={isModalOpen} setIsOpen={setIsModalOpen} modalId={'tng-Service-Modal'} modalTitleName={t('career.langTab30')} stat={'lang'} />
    </div>
  );
};

export default CareerLangTabComponent;
