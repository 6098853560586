import { FrameCol, FrameRow } from 'common/view/layouts/frames';
import { useTranslation } from 'react-i18next';

const CareerWelfareBenefitInfoComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <FrameRow>
        <FrameCol addClassName="careerWrap">
          <h2>{t('career.welfare1')}</h2>
          <FrameRow addClassName="mt-50">
            <FrameCol width={3} addClassName="col-sm-6">
              <div className="welfare">
                <p className="title">
                  {t('career.welfare2')}
                  <br />
                  {t('career.welfare3')}
                </p>
                <span>01</span>
              </div>
            </FrameCol>
            <FrameCol width={3}>
              <div className="welfare">
                <p className="title">{t('career.welfare4')}</p>
                <span>02</span>
              </div>
            </FrameCol>
            <FrameCol width={3}>
              <div className="welfare">
                <p className="title">{t('career.welfare5')}</p>
                <span>03</span>
              </div>
            </FrameCol>
            <FrameCol width={3}>
              <div className="welfare">
                <p className="title">{t('career.welfare6')}</p>
                <span>04</span>
              </div>
            </FrameCol>
            <FrameCol width={3}>
              <div className="welfare">
                <p className="title">{t('career.welfare7')}</p>
                <span>05</span>
              </div>
            </FrameCol>
            <FrameCol width={3}>
              <div className="welfare">
                <p className="title">{t('career.welfare8')}</p>
                <span>06</span>
              </div>
            </FrameCol>
            <FrameCol width={6}>
              <div className="welfare careerbg01">
                <p className="title">{t('career.welfare9')}</p>
                <span>07</span>
              </div>
            </FrameCol>
          </FrameRow>
          <FrameRow>
            <FrameCol width={3} addClassName="mobile-full">
              <FrameRow addClassName="h-100">
                <FrameCol addClassName="h-100">
                  <div className="welfare h-100 careerbg02">
                    <p className="title">{t('career.welfare10')}</p>
                    <span>08</span>
                  </div>
                </FrameCol>
              </FrameRow>
            </FrameCol>
            <FrameCol width={9}>
              <FrameRow>
                <FrameCol width={4}>
                  <div className="welfare">
                    <p className="title">{t('career.welfare11')}</p>
                    <span>09</span>
                  </div>
                </FrameCol>
                <FrameCol width={4}>
                  <div className="welfare">
                    <p className="title">{t('career.welfare12')}</p>
                    <span>10</span>
                  </div>
                </FrameCol>
                <FrameCol width={4}>
                  <div className="welfare">
                    <p className="title">{t('career.welfare13')}</p>
                    <span>11</span>
                  </div>
                </FrameCol>
                <FrameCol width={4}>
                  <div className="welfare">
                    <p className="title">{t('career.welfare14')}</p>
                    <span>12</span>
                  </div>
                </FrameCol>
                <FrameCol width={8}>
                  <div className="welfare">
                    <p className="title">{t('career.welfare15')}</p>
                    <span>13</span>
                  </div>
                </FrameCol>
              </FrameRow>
            </FrameCol>
          </FrameRow>
        </FrameCol>
      </FrameRow>
    </>
  );
};

export default CareerWelfareBenefitInfoComponent;
