import { FrameCol } from 'common/view/layouts/frames';
import { useTranslation } from 'react-i18next';

const CareerTabComponent = ({ rootUrl }: { rootUrl: string }) => {
  const { t } = useTranslation();

  return (
    <>
      <FrameCol textAlign="text-center">
        <h2>{t('career.tab1')}</h2>
        <p className="sub-title">
          {t('career.tab2')}
          <br />
          {t('career.tab3')}
        </p>
      </FrameCol>
      <div className="tab-wrap car-tab">
        <ul className="nav nav-tabs justify-content-center" role="tablist">
          <li className="nav-item it-service">
            <a className={`nav-link ${rootUrl === 'it' ? 'active' : ''}`} data-toggle="tab" href="#it-Service" role="tab">
              <span>{t('career.tab4')}</span>
            </a>
          </li>
          <li className="nav-item tng-service">
            <a className={`nav-link ${rootUrl === 'lang' || rootUrl === 'home' ? 'active' : ''}`} data-toggle="tab" href="#tng-Service" role="tab">
              <span>{t('career.tab5')}</span>
            </a>
          </li>
          <li className="nav-item e4net">
            <a className={`nav-link`} data-toggle="tab" href="#e4net-Service" role="tab">
              <span>{t('career.tab6')}</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default CareerTabComponent;
